module.exports = {
  SUPERADMIN_ONLY: 'superadmin_only',

  MODIFY_ACCOUNT_INTEGRATION: 'modify_account_integration',

  LIST_USERS: 'list_users',
  INVITE_USER: 'invite_user',
  MODIFY_USER: 'modify_user',

  MODIFY_SUBSCRIPTION: 'modify_subscription',

  CREATE_PROJECT: 'create_project',
  VIEW_PROJECT: 'view_project',
  EDIT_PROJECT: 'edit_project',
  DELETE_PROJECT: 'delete_project',

  UPLOAD_DOCUMENT: 'upload_document',
  REPROCESS_DOCUMENT: 'reprocess_document',
  VIEW_DOCUMENT: 'view_document',
  EDIT_DOCUMENT: 'edit_document',
  DELETE_DOCUMENT: 'delete_document',

  MODIFY_TAGS: 'modify_tags',

  MODIFY_CLAUSES: 'modify_clauses',

  MARK_REVIEWED: 'mark_reviewed',
  MARK_OUT_OF_SCOPE: 'mark_out_of_scope',

  MODEL_TRAINING: 'model_training',

  EDIT_RULES: 'edit_rules',

  MANAGE_TEMPLATES: 'manage_templates',
};
