const { EXAMPLE_MIN_COUNT_BEFORE_GENERATION } = require('shared/constants/example-suggestions');

module.exports = {
  // number of examples needed to trigger the different bot properties when training a model
  BOT_EXAMPLES_TEXT_THRESHOLD_ONE: EXAMPLE_MIN_COUNT_BEFORE_GENERATION,
  BOT_EXAMPLES_TEXT_THRESHOLD_TWO: 180,
  BOT_EXAMPLES_TEXT_THRESHOLD_THREE: 300,
  // f1 score difference for displaying messaging relating to model inconsistencies
  F1_DROP_THRESHOLD: 0.01,
  // number of f1 scores to keep for rolling calculations
  F1_HISTORY_LENGTH: 5,
  // negative example ratio thresholds for displaying relevant messaging in the text bubble
  RATIO_THRESHOLD_LOW: 2.5,
  RATIO_THRESHOLD_HIGH: 3.5,
  // minimum number of examples before example ratio messaging is shown
  RATIO_MESSAGING_MIN_EXAMPLES: 30,
};
