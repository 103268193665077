import axios from 'helpers/diligen-xhr-axios';
import { nameCompare } from 'helpers/sort-utils';
import { EVENTS } from 'shared/constants/events';
import { AND } from 'shared/constants/rules';

const DEFAULT_STOP_ON_SUCCESS = false;
const DEFAULT_CONJUNCTION = AND;

export default {
  state: {
    ruleSets: [],
    expandedRuleIds: [],
    expandedRuleSetIds: [],
    clauses: [],
  },
  getters: {
    routedRuleProject(state, getters, rootState) {
      const { project, project_fields, tags } = rootState.project;
      const sortedFields = [...project_fields].sort(nameCompare);

      return { ...project, projectFields: sortedFields, tags: [...tags] };
    },
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    /**
     * Collapses rule set (removes this rule set from those marked as expanded)
     * @param {object} state - vuex state to mutate
     * @param {number} ruleSetId - id of rule set to collapse
     */
    collapseRuleSet(state, ruleSetId) {
      state.expandedRuleSetIds = state.expandedRuleSetIds.filter((id) => id !== ruleSetId);
    },
    /**
     * Expands rule set (adds this rule set to those marked as expanded)
     * @param {object} state - vuex state to mutate
     * @param {number} ruleSetId - id of the rule set to expand
     */
    expandRuleSet(state, ruleSetId) {
      if (!state.expandedRuleSetIds.includes(ruleSetId)) {
        state.expandedRuleSetIds.push(ruleSetId);
      }
    },
    /**
     * Expands all rule sets
     * @param {object} state - vuex state to mutate
     */
    expandAllRuleSets(state) {
      state.expandedRuleSetIds = state.ruleSets.map((ruleSet) => ruleSet.id);
    },
    /**
     * Collapses rule (removes this rule from those marked as expanded)
     * @param {object} state - vuex state to mutate
     * @param {number} ruleId - id of rule to collapse
     */
    collapseRule(state, ruleId) {
      state.expandedRuleIds = state.expandedRuleIds.filter((id) => id !== ruleId);
    },
    /**
     * Expands rule (adds this rule to those marked as expanded)
     * @param {object} state - vuex state to mutate
     * @param {number} ruleId - id of rule to expand
     */
    expandRule(state, ruleId) {
      if (!state.expandedRuleIds.includes(ruleId)) {
        state.expandedRuleIds.push(ruleId);
      }
    },
    /**
    * Expands all rules
    * @param {object} state - vuex state to mutate
    */
    expandAllRules(state) {
      state.expandedRuleSetIds = state.ruleSets.map((ruleSet) => ruleSet.id);
      state.expandedRuleIds = state.ruleSets.reduce(
        (arr, ruleSet) => arr.concat(ruleSet.rules.map((rule) => rule.id)),
        [],
      );
    },
    /**
     * Collapses all rules and rulesets
     * @param {object} state - vuex state to mutate
     */
    collapseAll(state) {
      state.expandedRuleIds = [];
      state.expandedRuleSetIds = [];
    },
    /**
     * Sets clauses
     * @param {object} state - vuex state to mutate.
     * @param {Array} clauses
     */
    setClauses(state, clauses) {
      state.clauses = clauses;
    },
    /**
     * Prepends rule set. Remembers rule set as the one that was last added.
     * @param {object} state - vuex state to mutate.
     * @param {object} ruleSet - ruleSet to add.
     */
    addRuleSet(state, ruleSet) {
      state.ruleSets.unshift(ruleSet);
    },
    /**
     * Swaps rule set in state to a new, patched object.
     * @param {object} state - vuex state to mutate.
     * @param {object} patchedRuleSet - patched rule set to substitute it's old version.
     */
    patchRuleSet(state, patchedRuleSet) {
      const ruleSet = state.ruleSets.find(({ id }) => id === patchedRuleSet.id);
      if (ruleSet) {
        Object.assign(ruleSet, patchedRuleSet);
      }
    },
    /**
     * Adds a new rule to the rule set it belongs to.
     * @param {object} state - vuex state to mutate.
     * @param {object} rule - new rule to add.
     */
    addRule(state, rule) {
      const ruleSet = state.ruleSets.find(({ id }) => id === rule.ruleset_id);
      // Ensure any temporary rules are removed before adding the new rule.
      ruleSet.rules = ruleSet.rules
        .filter(({ id }) => id > 0)
        .concat(rule);
    },

    /**
     * Swaps action in state to a new, patched object.
     *
     * @param {object} state - vuex state to mutate.
     * @param {object} patchedAction - patched action to substitute it's old version.
     */
    patchAction(state, patchedAction) {
      const allRules = state.ruleSets.flatMap((ruleSet) => ruleSet.rules);
      const rule = allRules.find(({ id }) => id === patchedAction.rule_id);
      const swapPatched = (action) => (action.id === patchedAction.id ? patchedAction : action);
      rule.actions = rule.actions.map(swapPatched);
    },

    /**
     * Swaps rule in state to a new, patched object.
     * @param {object} state - vuex state to mutate.
     * @param {object} patchedRule - patched rule to substitute it's old version.
     */
    patchRule(state, patchedRule) {
      const ruleSet = state.ruleSets.find(({ id }) => id === patchedRule.ruleset_id);
      const rule = ruleSet.rules.find(({ id }) => id === patchedRule.id);
      Object.assign(rule, patchedRule);
    },

    /**
     * Swaps condition in state to a new, patched object.
     *
     * @param {object} state - vuex state to mutate.
     * @param {object} patchedCondition - patched condition to substitute it's old version.
     *
     */
    patchCondition(state, patchedCondition) {
      const allRules = state.ruleSets.flatMap(({ rules }) => rules);
      const rule = allRules.find(({ id }) => id === patchedCondition.rule_id);
      const swapPatched = (condition) => (condition.id === patchedCondition.id ? patchedCondition : condition);
      rule.conditions = rule.conditions.map(swapPatched);
    },

    /**
     * Adds condition to a rule it belongs to.
     * @param {object} state - vuex state to mutate.
     * @param {object} params
     * @param {number} params.ruleSetId - id of the rule set this condition belongs to.
     * @param {object} params.condition - condition to add.
     */
    addCondition(state, { ruleSetId, condition }) {
      const ruleSet = state.ruleSets.find(({ id }) => id === ruleSetId);
      const rule = ruleSet.rules.find(({ id }) => id === condition.rule_id);
      rule.conditions.push(condition);
    },
    /**
     * Adds action to a rule it belongs to.
     * @param {object} state - vuex state to mutate.
     * @param {object} params
     * @param {number} params.ruleSetId - id of the rule set this action belongs to.
     * @param {object} params.action - action to add.
     */
    addAction(state, { ruleSetId, action }) {
      const ruleSet = state.ruleSets.find(({ id }) => id === ruleSetId);
      const rule = ruleSet.rules.find(({ id }) => id === action.rule_id);
      rule.actions.push(action);
    },
    /**
      * Set rule sets.
      * @param {object} state - vuex state to mutate.
      * @param {Array<object>} ruleSets - rule sets to (re)init state with.
      */
    setRuleSets(state, ruleSets) {
      state.ruleSets = ruleSets;
    },
    /**
     * Removes a rule set from state.
     * @param {object} state - vuex state to mutate.
     * @param {number} id - id of the rule set to remove.
     */
    deleteRuleSet(state, id) {
      state.ruleSets = state.ruleSets.filter((ruleSet) => ruleSet.id !== id);
    },
    /**
     * Removes rule from the rule set it belonged to.
     * @param {object} state - vuex state to mutate.
     * @param {object} rule - rule to remove.
     */
    deleteRule(state, rule) {
      const ruleSet = state.ruleSets.find(({ id }) => id === rule.ruleset_id);
      ruleSet.rules = ruleSet.rules.filter(({ id }) => id !== rule.id);
    },
    /**
     * Removes condition from rule it belonged to.
     * @param {object} state - vuex state to mutate.
     * @param {object} params
     * @param {number} params.ruleSetId - id of the rule set this condition belonged to.
     * @param {object} params.deletedCondition - condition to remove.
     */
    deleteCondition(state, { ruleSetId, deletedCondition }) {
      const ruleSet = state.ruleSets.find(({ id }) => id === ruleSetId);
      const rule = ruleSet.rules.find(({ id }) => id === deletedCondition.rule_id);
      rule.conditions = rule.conditions.filter((condition) => condition.id !== deletedCondition.id);
    },
    /**
     * Removes action from rule it belonged to.
     * @param {object} state - vuex state to mutate.
     * @param {object} params
     * @param {number} params.ruleSetId - id of the rule set this action belonged to.
     * @param {object} params.deletedAction - action to remove.
     */
    deleteAction(state, { ruleSetId, deletedAction }) {
      const ruleSet = state.ruleSets.find(({ id }) => id === ruleSetId);
      const rule = ruleSet.rules.find(({ id }) => id === deletedAction.rule_id);
      rule.actions = rule.actions.filter((action) => action.id !== deletedAction.id);
    },
  },
  /* eslint-enable */
  actions: {
    /**
     * Sends rule set delete request, commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {number} id - id of action to delete.
     */
    async deleteRuleSet({ commit }, id) {
      await axios.delete(`/api/rulesets/${id}`);
      commit('deleteRuleSet', id);
    },

    /**
     * Sends post rule set request, commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {object} ruleSet
     * @param {string} ruleSet.name - name of the new rule set.
     * @param {number} ruleSet.projectId - id of the project the new rule set should belong to.
     * @param {number} ruleSet.order - The order of the rule set.
     */
    async addRuleSet({ commit }, { name, projectId, order }) {
      const response = await axios.post('/api/rulesets', {
        data: {
          attributes: {
            name,
            project_id: projectId,
            order,
          },
          relationships: {
            events: [{ event_type: EVENTS.ANALYSIS_COMPLETE }],
            rules: [{
              name: 'Untitled Rule',
              order: 0,
              conjunction: DEFAULT_CONJUNCTION,
              stop_on_success: DEFAULT_STOP_ON_SUCCESS,
            }],
          },
        },
      });
      const ruleset = response.data.data.ruleset;
      commit('expandRuleSet', ruleset.id);
      if (ruleset.rules.length) {
        commit('expandRule', ruleset.rules[0].id);
      }
      commit('addRuleSet', ruleset);
    },

    /**
     * Sends patch rule set request, commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {object} params
     * @param {number} params.id - id of the rule set to patch.
     * @param {object|undefined} params.attributes - patched rule set attributes.
     * @param {object|undefined} params.relationships - information about changes in related models.
     */
    async patchRuleSet({ commit }, { id, attributes, relationships }) {
      const payload = {
        data: {
          attributes,
          relationships,
        },
      };
      const response = await axios.patch(`/api/rulesets/${id}`, payload);
      const patchedRuleSet = response.data.data.ruleset;
      commit('patchRuleSet', patchedRuleSet);
    },

    /**
     * Sends post rule request, commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {object} context.state - state of this module.
     * @param {object} params
     * @param {number} params.ruleSetId - id of the rule set we add a new rule to.
     * @param {number} params.order - specifies order execution within the rule set.
     * @param {string} params.name - name of the new rule.
     */
    async addRule({ commit, state }, { ruleSetId, order, name }) {
      const payload = {
        data: {
          attributes: {
            stop_on_success: DEFAULT_STOP_ON_SUCCESS,
            conjunction: DEFAULT_CONJUNCTION,
            order,
            name,
          },
        },
      };

      const response = await axios.post(`/api/rulesets/${ruleSetId}`, payload);
      const rule = response.data.data.rule;
      const ruleSet = state.ruleSets.find(({ id }) => id === rule.ruleset_id);
      commit('expandRule', rule.id);
      commit('expandRuleSet', ruleSet.id);
      commit('addRule', rule);
    },

    /**
     * Sends rule delete request, commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {object} rule - rule to delete.
     */
    async deleteRule({ commit }, rule) {
      await axios.delete(`/api/rules/${rule.id}`);
      commit('deleteRule', rule);
    },

    /**
     * Sends a post request for a condition or action and commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {object} params
     * @param {'condition'|'action'} params.type - The resource type of this post request - either condition or action.
     * @param {object} params.rule - the rule context.
     * @param {object} params.attributes - attributes for the new condition.
     * @param {object} params.relationships - information about changes in related models.
     */
    async post({ commit }, { type, rule, attributes, relationships }) {
      const [endpoint, mutation] = [`${type}s`, `add${type.charAt(0).toUpperCase() + type.slice(1)}`];
      const url = `/api/rulesets/${rule.ruleset_id}/${rule.id}/${endpoint}`;
      const payload = { data: { attributes, relationships } };

      const response = await axios.post(url, payload);
      const data = response.data.data;
      const commitTag = (tag) => commit('project/addTag', tag, { root: true });

      if (data.tags?.length) {
        data.tags.forEach(commitTag);
      }
      if (data.tag) {
        commitTag(data.tag);
      }
      commit(mutation, { ruleSetId: rule.ruleset_id, [type]: data[type] });
    },

    /**
     * Sends condition delete request, commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {object} params
     * @param {object} params.ruleSetId - id of rule set the deleted condition belongs to.
     * @param {object} params.condition - condition to delete.
     */
    async deleteCondition({ commit }, { ruleSetId, condition }) {
      await axios.delete(`/api/conditions/${condition.id}`);
      commit('deleteCondition', { ruleSetId, deletedCondition: condition });
    },

    /**
     * Sends action delete request, commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {object} params
     * @param {object} params.ruleSetId - id of the rule set the deleted action belongs to.
     * @param {object} params.action - action to delete.
     */
    async deleteAction({ commit }, { ruleSetId, action }) {
      await axios.delete(`/api/actions/${action.id}`);
      commit('deleteAction', { ruleSetId, deletedAction: action });
    },

    /**
     * Fetches rule sets for a given project and commits them to the store.
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.commit - The Vuex commit method.
     * @param {Object} payload - The action payload.
     * @param {string} payload.projectId - The ID of the project to fetch rule sets for.
     */
    async fetchProjectRuleSets({ commit }, { projectId }) {
      const response = await axios.get(`/api/projects/${projectId}/rulesets`);
      const rulesets = response.data.data.rulesets;
      commit('setRuleSets', rulesets);
    },

    /**
     * Sends patch condition request, commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {object} params
     * @param {number} params.ruleSetId - id of the rule set patched condition belongs to.
     * @param {object} params.condition - patched condition.
     * @param {object} params.relationships - related POSTed data (like tags that we create and apply in one go)
     */
    async patchCondition({ commit }, { ruleSetId, condition, relationships }) {
      const { data, condition_type, negate } = condition; // Destructure needed properties
      const attributes = { data, condition_type, negate }; // Restructure them into a new object
      const payload = {
        data: {
          attributes,
          relationships,
        },
      };
      const url = `/api/conditions/${condition.id}`;

      const response = await axios.patch(url, payload);
      const patchedCondition = response.data.data.condition;
      const tag = response.data.data.tag;
      if (tag) {
        commit('project/addTag', tag, { root: true });
      }
      commit('patchCondition', patchedCondition);
    },

    /**
     * Sends patch action request (if valid), commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {object} params
     * @param {number} params.id - id of the patched action.
     * @param {object} params.attributes - patched action attributes.
     * @param {object} params.relationships - related POSTed data (like tags that we create and apply in one go)
     */
    async patchAction({ commit }, { id, attributes, relationships }) {
      const payload = {
        data: {
          attributes,
          relationships,
        },
      };
      const url = `/api/actions/${id}`;

      const response = await axios.patch(url, payload);
      const patchedAction = response.data.data.action;
      const newProjectTags = response.data.data.tags;
      if (newProjectTags) {
        newProjectTags.forEach((tag) => commit('project/addTag', tag, { root: true }));
      }
      commit('patchAction', patchedAction);
    },

    /**
     * Gets and commits all system clauses.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     */
    async fetchClauses({ commit }) {
      const response = await axios.get('/api/clauses');
      commit('setClauses', response.data.data);
    },
    /**
     * Sends patch rule request, commits changes.
     * @param {object} context - vuex action context.
     * @param {Function} context.commit - function committing mutations.
     * @param {object} params
     * @param {object} params.rule - rule we are patching.
     * @param {object} params.attributes - patched rule attributes.
     */
    async patchRule({ commit }, { rule, attributes }) {
      const payload = {
        data: {
          attributes,
        },
      };
      const url = `/api/rules/${rule.id}`;
      const response = await axios.patch(url, payload);
      const patchedRule = response.data.data.rule;
      commit('patchRule', patchedRule);
    },
  },
};
