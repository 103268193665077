import { get } from 'lodash';
import { DEFAULT_SORT } from 'shared/constants/document-fields';
import { createEmptyFilter } from 'helpers/project-filters';

/**
 * Get what we want to sort by from the query.
 * @param  {object} query - query data in form of an object.
 * @return {string} sort containing field and desc/asc information.
 */
const sort = (query) => get(query, 'sort', DEFAULT_SORT);

/**
 * Get information about filtering applied to the documents in the project.
 * @param  {object} query - query data in form of an object.
 * @return {object} filters applied to the PV.
 */
const filters = (query) => ({ ...createEmptyFilter(), ...query.filter });

export {
  filters,
  sort,
};
