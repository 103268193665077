const { PROJECT_LEADER, PROJECT_MEMBER } = require('shared/constants/project-roles');

function isLeader(collaborator) {
  return collaborator.project_role === PROJECT_LEADER;
}

function isTeamMember(collaborator) {
  return collaborator.project_role === PROJECT_MEMBER;
}

module.exports = {
  isLeader,
  isTeamMember,
};
