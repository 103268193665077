import request from 'helpers/diligen-xhr';
import { get } from 'lodash';

export default {
  state: {
    usage: {},
  },
  getters: {
    isOverProcessingLimit({ usage }) {
      const current = parseInt(get(usage, 'current.monthly.reviews'), 10);
      const limit = parseInt(get(usage, 'limits.monthly.reviews'), 10);
      return current >= limit;
    },
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    setUsage(state, data) {
      state.usage = data;
    },
  },
  /* eslint-enable */
  actions: {
    /**
     * Load and save usage in store.
     *
     * @param {function} commit - Function to commit changes via mutators.
     */
    initUsage: async ({ commit }) => {
      const data = (await request.get('/api/usage')).body.data;
      commit('setUsage', data);
    },
  },
};
