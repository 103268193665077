const EXECUTION_STATUSES = {
  'Fully Executed': 'fully_executed',
  'Partially Executed': 'partially_executed',
  'Not Executed': 'not_executed',
  'Draft Agreement': 'draft_agreement',
  'Not Applicable': 'not_applicable',
};

const availableValues = Object.values(EXECUTION_STATUSES);

function getExecutionStatusLabel(executionStatusValue) {
  const label = Object.keys(EXECUTION_STATUSES).find((key) => EXECUTION_STATUSES[key] === executionStatusValue);
  return label || null;
}

function getExecutionStatusValue(executionStatusLabel) {
  const value = Object.values(EXECUTION_STATUSES).find((val) => val === EXECUTION_STATUSES[executionStatusLabel]);
  return value || null;
}

module.exports = {
  EXECUTION_STATUSES,
  availableValues,
  getExecutionStatusLabel,
  getExecutionStatusValue,
};
