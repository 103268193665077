const dayjs = require('dayjs');
const { getNonDataColumn, NON_DATA_FIELD_NAMES } = require('shared/constants/document-fields');

const { DUPLICATES } = NON_DATA_FIELD_NAMES;

/**
 * Get paths of files that are considered duplicates
 * @param  {object} doc - document model
 * @return {Array<string>} paths
 */
const getDuplicatePaths = (doc) => {
  const pathsColumn = getNonDataColumn(DUPLICATES);
  return doc[pathsColumn]
    ? doc[pathsColumn].split(',').map((path) => (path.startsWith('/') ? path.substring(1) : path))
    : [];
};

/**
 * Format a non-data field timestamp
 *
 * @param  {object} doc - Object containing the field value
 * @param  {string} dateFormat - The preferred date format
 * @param  {string} fieldName - The non-data field name to format
 *
 * @return {string} - The formatted date
 */
const getFormattedDateField = (doc, dateFormat, fieldName) => {
  const dateValue = doc[getNonDataColumn(fieldName)];
  if (dateValue) {
    return dayjs(dateValue).format(`${dateFormat} hh:mm a`);
  }
};

module.exports = {
  getDuplicatePaths,
  getFormattedDateField,
};
