const { values } = require('lodash');

const SPECIAL_DATA_FIELD_NAMES = {
  CONTRACT_DATE: 'Contract Date',
  CONTRACT_NAME: 'Contract Name',
  DOCUMENT_TYPE: 'Document Type',
  GOVERNING_LAW_PLACES: 'Governing Law Places',
  CONTRACT_PARTIES: 'Contract Parties',
};

const NON_DATA_FIELD_NAMES = {
  ADDED_ON: 'Added On',
  DOCUMENT_NUMBER: 'Document Number',
  DUPLICATES: 'Duplicates',
  EXECUTION: 'Execution',
  FILE: 'File',
  FOLDER: 'Folder',
  LAST_REVIEW_TIME: 'Last Review Time',
  NUMBER_OF_PAGES: 'Pages',
  REVIEW: 'Review',
  STATUS: 'Status',
  TAGS: 'Tags',
  HAS_OCR: 'Document OCR',
};

const AVAILABLE_FEATURES = [
  'Added On',
  'Contract Date',
  'Contract Name',
  'Contract Parties',
  'Document Hyperlink',
  'Document Number',
  'Document Type',
  'Duplicates',
  'Execution',
  'Filename',
  'Folder',
  'Governing Law Places',
  'Last Review Time',
  'Pages',
  'Reviewer',
  'Review Status',
  'Status',
  'Tags',
];

const DUPLICATES_COUNT_COLUMN = 'duplicates_count';

const SPECIAL_DOCUMENT_FIELD_NAMES = {
  ...SPECIAL_DATA_FIELD_NAMES,
  ...NON_DATA_FIELD_NAMES,
};

const DEFAULT_SORT = SPECIAL_DOCUMENT_FIELD_NAMES.ADDED_ON;

const NON_DATA_FIELD_NAME_TO_COLUMN = {
  [NON_DATA_FIELD_NAMES.ADDED_ON]: 'added_on',
  [NON_DATA_FIELD_NAMES.DOCUMENT_NUMBER]: 'id',
  [NON_DATA_FIELD_NAMES.DUPLICATES]: 'duplicate_paths',
  [NON_DATA_FIELD_NAMES.FILE]: 'file_name',
  [NON_DATA_FIELD_NAMES.NUMBER_OF_PAGES]: 'number_of_pages',
  [NON_DATA_FIELD_NAMES.FOLDER]: 'folder',
  [NON_DATA_FIELD_NAMES.EXECUTION]: 'execution_status',
  [NON_DATA_FIELD_NAMES.STATUS]: 'file_status',
  [NON_DATA_FIELD_NAMES.LAST_REVIEW_TIME]: 'last_review_time',
  [NON_DATA_FIELD_NAMES.HAS_OCR]: 'ocr_file_path',
};

const getNonDataColumn = (name) => NON_DATA_FIELD_NAME_TO_COLUMN[name];
const getNonDataSortableColumn = (name) =>
  (name === NON_DATA_FIELD_NAMES.DUPLICATES ? DUPLICATES_COUNT_COLUMN : getNonDataColumn(name));

// all special fields except REVIEW are sortable
const isSpecialFieldSortable = (fieldName) =>
  values(SPECIAL_DOCUMENT_FIELD_NAMES).includes(fieldName) && fieldName !== SPECIAL_DOCUMENT_FIELD_NAMES.REVIEW;

module.exports = {
  AVAILABLE_FEATURES,
  DEFAULT_SORT,
  isSpecialFieldSortable,
  getNonDataColumn,
  getNonDataSortableColumn,
  NON_DATA_FIELD_NAMES,
  SPECIAL_DATA_FIELD_NAMES,
  SPECIAL_DOCUMENT_FIELD_NAMES,
};
