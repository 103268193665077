module.exports = {
  FILE_DELETED: 'File Deleted',
  FILE_STATUS_UPDATED: 'File Status Updated',
  FILE_UPLOADED: 'File Uploaded',
  FILE_UPDATED: 'File Updated',
  LAYOUTS_DELETED: 'Layouts Deleted',
  PROJECT_FIELD_CREATED: 'Project Field Created',
  PROJECT_FIELD_DELETED: 'Project Field Deleted',
  PROJECT_FIELD_UPDATE: 'Project Field Updated',
  REVIEW_UPDATE: 'Review Status Updated',
  REVIEWERS_UPDATED: 'Reviewers Updated',
  TAGS_MODIFIED: 'Tags Modified',
  TEAM_UPDATED: 'Team Updated',
};
